export function usePageTracking(trackingData?: TrackingData) {
  const { data: jobShop } = useNuxtData("jobShopData")
  const { $cookieConsent } = useNuxtApp()

  /**
   * register tracking events after mounting the rendered page
   */
  onMounted(() => {
    trackPageViewAmpl(trackingData)

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden")
        trackScrollDepth()
    })

    window.addEventListener("beforeunload", () => {
      trackScrollDepth()
    })

    if (jobShop.value?.externalScriptSettings.isCookielessTrackingEnabled) {
      trackPageViewMatomo()
    }
    else {
      // Check consent status whenever it changes
      watch($cookieConsent.checkConsent, (hasConsent) => {
        if (hasConsent) {
          // Small delay to ensure GTM is enabled
          setTimeout(() => {
            trackPageViewGTM()
          }, 100)
        }
      }, { immediate: true })
    }
  })
}
