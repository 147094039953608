<template>
  <div ref="wrapper">
    <slot />
  </div>
</template>

<script>
import { onMounted, ref } from "vue"

export default {
  name: "IntersectWrapper",

  setup(props, { emit }) {
    const wrapper = ref(null)

    onMounted(() => {
      emit("mounted")

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting)
              emit("intersects")
          })
        },
        { threshold: [0.1] },
      )

      observer.observe(wrapper.value.children[0])
    })

    return {
      wrapper,
    }
  },
}
</script>
